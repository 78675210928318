import React, { Component } from 'react'
import classNames from 'classnames'

export class Paginator extends Component {
  setPage(page) {
    this.props.setPage(page)
  }

  render() {
    const view = this

    // Если не полностью инициализирован или не требуется (страница всего одна)
    // ничего не выводим
    if (
      this.props.count_pages === 1 ||
      this.props.current_page === undefined
    ) {
      return null
    }

    const pages_range = []
    const n_range = 7
    const current_page = this.props.current_page
    const count_pages = this.props.count_pages

    let start_page
    let end_page

    // Левая часть
    start_page = current_page > n_range ? current_page - n_range : 1

    if (start_page > 1) {
      pages_range.push(1)
    }

    for (let i = start_page; i < current_page; i++) {
      pages_range.push(i)
    }

    // Текущая страница
    pages_range.push(current_page)

    // Правая часть
    start_page = current_page < count_pages ? current_page + 1 : count_pages
    end_page =
      current_page + n_range < count_pages
        ? current_page + n_range + 1
        : count_pages
    for (let i = start_page; i < end_page; i++) {
      pages_range.push(i)
    }

    if (count_pages && pages_range.indexOf(count_pages) === -1) {
      pages_range.push(count_pages)
    }

    const pagination_elements = []

    if (this.props.current_page !== 1) {
      pagination_elements.push(
        <li className="page-item prev" key="prev">
          <a
            className="page-link waves-effect"
            onClick={(e) => {
              view.setPage(current_page - 1)
            }}
          >
            <i className="tf-icon fs-6 ti ti-chevrons-left"></i>
          </a>
        </li>
      )
    }

    pages_range.forEach((page) => {
      if (page === current_page) {
        pagination_elements.push(
          <li className="page-item active" key={page}>
            <a className="page-link waves-effect">
              {page} <span className="sr-only">(current)</span>
            </a>
          </li>
        )
      } else {
        pagination_elements.push(
          <li className="page-item" key={page}>
            <a
              className="page-link waves-effect"
              onClick={(e) => {
                view.setPage(page)
              }}
            >
              {page}{' '}
            </a>
          </li>
        )
      }
    })

    if (this.props.has_next) {
      pagination_elements.push(
        <li className="page-item next" key="next">
          <a
            className="page-link waves-effect"
            onClick={(e) => {
              view.setPage(current_page + 1)
            }}
          >
            <i className="tf-icon fs-6 ti ti-chevrons-right"></i>
          </a>
        </li>
      )
    }

    return (
      <nav>
        <ul className="pagination pagination-sm">{pagination_elements}</ul>
      </nav>
    )
  }
}

export class Panel extends Component {

  constructor(props) {
    super(props)
    this.state = { show: props.collapsed === undefined ? true : !props.collapsed }
    this.toggleShow = this.toggleShow.bind(this)
  }

  toggleShow() {
    const that = this

    this.setState({ show: !this.state.show }, () => {
      if (that.props.toggleShow) {
        that.props.toggleShow(that.state.show)
      }
    })
  }

  render() {
    const props = this.props
    const bodyShow = this.state.show
    const lazyContent = props.lazyContent

    const iconClass = classNames("tf-icons ti scaleX-n1-rtl ti-sm", {
      "ti-chevron-down": bodyShow,
      "ti-chevron-left": !bodyShow,
    })

    let cardBody = null;

    if (bodyShow) {
      if (props.noWrapBody) {
        cardBody = props.children || lazyContent()
      }
      else {
        cardBody = <div className="card-body p-3 pt-0">
          {props.children || lazyContent()}
        </div>
      }
    }

    return (
      <div className="card card-action mb-3">
        <div className="card-header p-3">
          <h5 className="card-action-title mb-0">{props.header}</h5>
          <div className="card-action-element">
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                <span className="card-collapsible pointer" onClick={this.toggleShow}>
                  <i className={iconClass}></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="collapse show">
          {cardBody}
        </div>
      </div>
    )
  }
}
